import { useCallback, useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { useMiniCartContext } from '@miniCart/context';

import CART_QUERY from '@miniCart/graphql/getCart.graphql';

const miniCartSelector = '[data-react-minicart]';

export const useTrigger = () => {
    const [{ items, cartId }, { actions }] = useMiniCartContext();
    const [fetchCart, { loading, data, error }] = useLazyQuery(CART_QUERY, {
        variables: { cartId },
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
    });

    useEffect(() => {
        if (cartId) {
            fetchCart();
        }
    }, [cartId, fetchCart]);

    const handleDataChange = useCallback(() => {
        const miniCartContainer = document.querySelector(miniCartSelector);
        const { cartId } = miniCartContainer.dataset;
        if (cartId) {
            actions.setCartId(cartId);
            fetchCart();
        }
    }, [fetchCart, actions.setCardId]);

    // Add event listener to respond to items being added to cart, clean up on unmount
    useEffect(() => {
        handleDataChange(); // Trigger it initially in case the first event has already been sent
        const miniCartContainer = document.querySelector(miniCartSelector);
        miniCartContainer.addEventListener('datachange', handleDataChange);

        return () => {
            const miniCartContainer = document.querySelector(miniCartSelector);
            miniCartContainer.removeEventListener(
                'datachange',
                handleDataChange
            );
        };
    }, [handleDataChange]);

    /**
     * Handle response from fetch cart query
     */
    useEffect(() => {
        if (error && !error.message.includes('Required parameter')) {
            actions.setError(error.message);
            if (!error.message.includes('out of stock')) {
                return;
            }
        }

        if (loading) {
            actions.setIsLoading(true);
            return;
        }

        if (data) {
            actions.setFreeShippingIndicatorValues(data.cart);
            actions.setCart(data.cart);
            const subtotal = data.cart.prices.subtotal_with_discount_including_tax.value;
            actions.setSubtotal(subtotal);
        }
    }, [error, loading, data]);

    const toggleMiniCart = () => {
        actions.toggleIsOpen();
    };

    const quantity = useMemo(
        () => items.reduce((sum, item) => sum + item.quantity, 0),
        [items]
    );

    return {
        quantity,
        toggleMiniCart
    };
};
